<template>
  <b-row class="align-items-center justify-content-center">
    <div class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5">
      <h1 class="display-4 text-center mb-3">
        {{ $t("auth.login") }}
      </h1>
      <p class="text-muted text-center mb-5">{{ $t("auth.zesec") }}</p>
      <b-form @submit.stop.prevent="login" novalidate>
        <b-form-group :label="$t('phoneInput.phoneNumberLabel')">
          <vue-phone-number-input
            id="PhoneNumber"
            class="input-field"
            v-model="$v.form.PhoneNumber.$model"
            :error="!validPhone && submitted"
            @update="validatePhone"
            :translations="$t('phoneInput')"
            valid-color="#1e90ff"
            error-color="#e63857"
            required
            clearable
            fetch-country
            type="text"
          />
          <span id="phoneNumber-feedback" v-if="!validPhone && submitted" style="color: #e63857;  font-size: 0.8125rem">
            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
              {{
                  $t("errors.required_phone_number")
              }}
          </span>
        </b-form-group>
        <b-form-group>
          <div class="row">
            <b-col>
              <label>{{ $t("auth.password") }}</label>
            </b-col>
            <b-col cols="auto">
              <b-link
                :to="{ name: 'password-reset' }"
                class="form-text small text-muted"
                >{{ $t("auth.forgot_password") }}</b-link
              >
            </b-col>
          </div>
          <b-input-group>
            <b-form-input
              id="password"
              class="input-field"
              :class="{'code--error':codeError}"
              :placeholder="$t('auth.password')"
              :type="showPassword ? 'text' : 'password'"
              v-model="$v.form.Password.$model"
              :state="validateState('Password')"
            />
            <b-input-group-append>
              <b-button type="button" @click="showPassword = !showPassword">
                  <span class="icon is-small is-right"><b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"/></span>
              </b-button>
            </b-input-group-append>
              <b-form-invalid-feedback id="Password-feedback" style="color: #e63857">
                  <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                  {{ $t("errors.required_password") }}
              </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
        <b-button
          size="lg"
          class="mb-3"
          block
          variant="primary"
          type="submit"
          @click.stop.prevent="login"
          >{{ $t("buttons.login") }}</b-button
        >
        <div class="text-center">
          <small class="text-muted text-center">
            {{ $t("auth.new") }}
            <b-link :to="{ name: 'register' }">{{
              $t("auth.register")
            }}</b-link>
          </small>
        </div>
        <b-row class="mt-3">
          <b-col v-if="codeError">
            <b-alert show variant="light">
              <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                {{
                    $t("errors.wrong_password")
                }}
            </b-alert>
          </b-col>
        </b-row>
      </b-form>
      <language-switcher class="p-0 w-100 mt-3" />
    </div>
    <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
      <div class="bg-cover vh-100 mt-n1 mr-n3 auth-bg"></div>
    </div>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import { AUTH_LOGIN, AUTH_ERROR_CLEAR } from "@/store/types/auth";
import { INIT_STARTED, INIT_COMPLETED } from "@/store/types/global";
import { USER_GET } from "@/store/types/user";
import { validationMixin } from "vuelidate";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import VuePhoneNumberInput from "vue-phone-number-input";
import LanguageSwitcher from "@/components/common/LanguageSwitcher";

export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        PhoneNumber: "",
        Password: "",
      },
      validPhone: false,
      fullPhone: null,
      submitted: false,
      codeError: null,
      showPassword: false,
    };
  },
  computed: {
    ...mapGetters(["getAuthErrors"]),
  },
  validations: {
    form: {
      PhoneNumber: {
        required,
        phone: function () {
          return this.validPhone;
        },
      },
      Password: {
        required,
      },
    },
  },
  beforeCreate() {
    this.$nextTick().then(() =>
      document.body.classList.add(
        "d-flex",
        "align-items-center",
        "bg-auth",
        "border-top",
        "border-top-2",
        "border-primary"
      )
    );
  },
  mounted() {
    this.$store.commit(INIT_COMPLETED);
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty && this.submitted ? !$error : null;
    },
    validatePhone(phone) {
      this.validPhone = phone.isValid;
      this.fullPhone = phone;
    },
    login() {
      this.codeError = null;
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.$store
        .dispatch(AUTH_LOGIN, {
          PhoneNumber: this.fullPhone.formattedNumber,
          Password: this.form.Password,
        })
        .then((response) => {
            this.$session.start();
            this.$store.commit(INIT_STARTED);
            this.$store.dispatch(USER_GET).then((response) => {
              this.$session.set("userID", response.Id);
              /*this.$intercom.boot({
                user_id: response.Id,
                name: `${response.FirstName} ${response.LastName || ""}`,
                email: response.Email,
                language_override: this.$i18n.locale,
                avatar: {
                  type: "avatar",
                  image_url: response.SmallImageUrl,
                },
              });*/
            });
            document.body.classList.remove(
              "d-flex",
              "align-items-center",
              "bg-auth",
              "border-top",
              "border-top-2",
              "border-primary"
            );
            this.$router.push({
              name: "dashboard",
            });
        })
        .catch((error) => {
          this.codeError = this.$t("errors.wrong_password")
        });

    },
  },
  components: {
    "vue-phone-number-input": VuePhoneNumberInput,
    "language-switcher": LanguageSwitcher,
  },
  destroyed() {
    this.$store.commit(AUTH_ERROR_CLEAR);
  },
};
</script>
<style scoped>
.code--error {
    border-color: #e63857;
}
</style>
